import React from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"
import tw from "twin.macro"

import projects from "../../projects.json"

import Layout from "../components/layout"
// import SEO from "../components/seo"
import Chip from "@material-ui/core/Chip"
import LanguageIcon from "@material-ui/icons/Language"
import AppleIcon from "@material-ui/icons/Apple"
import AndroidIcon from "@material-ui/icons/Android"

let realms = {
  web: <LanguageIcon style={{ color: "#1d1d1d" }} />,
  ios: <AppleIcon style={{ color: "#1d1d1d" }} />,
  android: <AndroidIcon style={{ color: "#1d1d1d" }} />,
}

let stack = {
  apollo: require("../images/logos/apollo.png"),
  bootstrap: require("../images/logos/bootstrap.png"),
  cloudinary: require("../images/logos/cloudinary.png"),
  css: require("../images/logos/css.png"),
  firebase: require("../images/logos/firebase.png"),
  gatsby: require("../images/logos/gatsby.png"),
  googledrive: require("../images/logos/googledrive.png"),
  graphql: require("../images/logos/graphql.png"),
  handlebars: require("../images/logos/handlebars.png"),
  html: require("../images/logos/html.png"),
  hubspot: require("../images/logos/hubspot.png"),
  js: require("../images/logos/js.png"),
  jquery: require("../images/logos/jquery.png"),
  materialdesign: require("../images/logos/materialdesign.png"),
  netlify: require("../images/logos/netlify.png"),
  expressjs: require("../images/logos/expressjs.png"),
  react: require("../images/logos/react.png"),
  tailwind: require("../images/logos/tailwind.png"),
  ts: require("../images/logos/ts.png"),
  sanity: require("../images/logos/sanity.png"),
  shopify: require("../images/logos/shopify.png"),
  strapi: require("../images/logos/strapi.png"),
  sqlite: require("../images/logos/sqlite.png"),
  wordpress: require("../images/logos/wordpress.png"),
  nodejs: require("../images/logos/nodejs.png"),
  pkg: require("../images/logos/pkg.png"),
  heroku: require("../images/logos/heroku.png"),
  styledComponents: require("../images/logos/styled-components.png"),
  sendGrid: require("../images/logos/sendgrid.png"),
}

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 2rem 1rem;
  ${tw`md:(h-screen)`}
`

const InnerContainer = styled.div`
  color: #1d1d1d; 
  ${tw`md:w-2/5`}
`

const ProjectPage = props => {
  let project = projects.filter(obj => {
    return obj.short === props["*"]
  })
  project = project[0]
  if (project === undefined) {
    project = {
      color: "#FE6B64",
    }
  }
  return (
    <Layout>
      {/* <SEO title={props["*"]} /> */}
      <Container
        style={{
          backgroundColor: project?.color,
        }}
      >
        <InnerContainer>
          <h1>{project?.name}</h1>
          <p style={{ hyphens: "auto", textAlign: "justify" }}>
            {project?.text}
          </p>
          { project?.links && (<div>
            <h4>Deployed:</h4>
            <div id="realm-box-outer">
              {project?.links?.map(link => {
                return (
                  <Chip
                    avatar={realms[link.realm]}
                    label={link.realm}
                    href={link.link}
                    clickable
                    component="a"
                    variant="outlined"
                    target="_blank"
                    style={{
                      // backgroundColor: "#F5F5DC",
                      color: "#1d1d1d",
                      fontFamily: "roboto slab",
                      marginRight: 10,
                      borderColor: "#1d1d1d",
                      fontSize: "1.1rem",
                    }}
                  />
                )
              })}
            </div>
          </div>)}
          <div>
            <h4>Technologies:</h4>
            <div id="logo-box-outer">
              {project?.stack?.map(tech => {
                return (
                  <div id="logo-box">
                    <p></p>
                    <img id="logo" src={stack[tech]} alt="" />
                  </div>
                )
              })}
            </div>

            <h4
              onClick={() => {
                navigate(-1)
              }}
              style={{ color: "#1d1d1d", cursor: "pointer" }}
            >
              Go back
            </h4>
          </div>
        </InnerContainer>
      </Container>
    </Layout>
  )
}
export default ProjectPage
